@charset "utf-8";

.contents {
  @include base-wrap--space;
  @include flex();
  flex-direction: row-reverse;
  margin-top: 60px;
  margin-bottom: 60px;
  @include mq(sp) {
    margin-top: 30px;
  }
}
.main {
  width: 850px;
  @include mq(sp) {
    width: auto;
    margin-bottom: 50px;
  }
}
.side {
  width: 300px;
  @include mq(sp) {
    width: auto;
  }
}

.base-wrap {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  &--space {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.screen-reader-text {
  display: none;
}
