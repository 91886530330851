@charset "utf-8";

/*--------------------------------------------------------
  ページ上部へ戻るボタン
----------------------------------------------------------*/
.page-top {
  position: fixed;
  bottom: 50px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: 550px;
  background: $light-blue;
  opacity: 0.8;
  cursor: pointer;
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 25px;
  @include mq(sp) {
    left: auto;
    right: 15px;
    bottom: 50px;
    margin-left: 0;
  }
}