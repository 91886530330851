@charset "utf-8";

.text {
	line-height: $base-lh;
}
.hidden {
	display: none !important;
	visibility: hidden;
}
.clearfix {
	@include clearfix;
}
.clear {
	clear: both;
}

/* show only  SP/PC
-------------------------------*/

.sp {
	@include mq(pc) {
		display: none !important;
	}
}
.sp-s {
	@include mq(pc) {
		display: none !important;
	}
	@include mq(tab) {
		display: none !important;
	}
	@include mq(sp) {
		display: block !important;
	}
}

.pc {
	@include mq(sp) {
		display: none !important;
	}
}

.pc-s {
	@include mq(tab) {
		display: block !important;
	}
	@include mq(sp) {
		display: none !important;
	}
}

/* @group float
-------------------------------- */
.right {
  float: right;
}
.left {
  float: left;
}
.img-left {
  float: left;
  margin: 0 20px 10px 0;
}
.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

/* @text-align
-------------------------------- */
.ta-right {
  text-align: right !important;
}
.ta-center {
  text-align: center !important;
}
.ta-left {
  text-align: left !important;
}

/* @vertical-align
-------------------------------- */
.va-top {
  vertical-align: top !important;
}
.va-bottom {
  vertical-align: bottom !important;
}
.va-middle {
  vertical-align: middle !important;
}

/* @group color
-------------------------------- */
.red {
  color: #EA5413 !important;
}
.blue {
  color: #0099ff !important;
}
.gray {
  color: #666 !important;
}
.pink {
  color: #C60070 !important;
}
.peach {
  color: #F86363 !important;
}
.green {
  color: #89B929 !important;
}

/* @group margin
-------------------------------- */
.m0 {
  margin: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
 }
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.mb70 {
  margin-bottom: 70px !important;
}
.mb80 {
  margin-bottom: 80px !important;
}
.mb90 {
  margin-bottom: 90px !important;
}
.mb100 {
  margin-bottom: 100px !important;
}
.mt0 {
  margin-top: 0px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mt70 {
  margin-top: 70px !important;
}
.mt80 {
  margin-top: 80px !important;
}
.mt90 {
  margin-top: 90px !important;
}
.mt100 {
  margin-top: 100px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.mr40 {
  margin-right: 40px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}

/* @font size
-------------------------------- */
.fs10 {
  font-size: 10px !important;
}
.fs11 {
  font-size: 11px !important;
}
.fs12 {
  font-size: 12px !important;
}
.fs13 {
  font-size: 13px !important;
}
.fs14 {
  font-size: 14px !important;
}
.fs15 {
  font-size: 15px !important;
}
.fs16 {
  font-size: 16px !important;
}
.fs17 {
  font-size: 17px !important;
}
.fs18 {
  font-size: 18px !important;
}
.fs19 {
  font-size: 19px !important;
}
.fs20 {
  font-size: 20px !important;
}
.fs21 {
  font-size: 21px !important;
}
.fs22 {
  font-size: 22px !important;
}
.fs23 {
  font-size: 23px !important;
}
.fs24 {
  font-size: 24px !important;
}
.fs25 {
  font-size: 25px !important;
}
.fs26 {
  font-size: 26px !important;
}
.fs27 {
  font-size: 27px !important;
}
.fs28 {
  font-size: 28px !important;
}
.fs29 {
  font-size: 29px !important;
}
.fs30 {
  font-size: 30px !important;
}
.fs31 {
  font-size: 31px !important;
}
.fs32 {
  font-size: 32px !important;
}
.fs34 {
  font-size: 34px !important;
}
.fs36 {
  font-size: 36px !important;
}
.fs38 {
  font-size: 38px !important;
}
.fs48 {
  font-size: 48px !important;
}
.fs56 {
  font-size: 56px !important;
}
.fs64 {
  font-size: 64px !important;
}

/* @font-weight
-------------------------------- */
.fw-bold {
  font-weight: bold !important;
}

/* @border
-------------------------------- */
.bb-none {
  border-bottom: 0 !important;
}
.bl-none {
  border-left: 0 !important;
}
.br-none {
  border-right: 0 !important;
}
.bt-none {
  border-top: 0 !important;
}

/* @status font color
-------------------------------- */
.required {
  color: #c00;
}
.error-message {
  color: #c00;
}

/* @wp image
-------------------------------- */
.alignleft {
	float: left;
	margin: 0 20px 10px 0;
}
.alignright {
	float: right;
	margin: 0 0 10px 20px;
}
.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/* @flex
-------------------------------- */
.flex {
	@include flex();
}
.flex-sp {
	@include flex-sp();
}

/* @font-family
-------------------------------- */
.mincho {
	font-family: $mincho;
}
.yu {
  font-family: $font-yu;
}
.noto {
  font-family: $font-noto;
}
