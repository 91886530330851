@charset "utf-8";

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
.title01 {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 2px solid #E1E1E1;
  color: $blue;
  font-size: 26px;
  font-weight: bold;
  @include mq(sp) {
    margin-bottom: 20px;
    @include fz_vw(20);
  }
  span {
    margin-left: 30px;
    color: $black;
    font-size: 14px;
    font-weight: normal;
    @include mq(sp) {
      margin-left: 15px;
      @include fz_vw(13);
    }
  }
  &::after {
    position: absolute;
    bottom: -2px;
    left: 0;
    content: "";
    display: block;
    width: 320px;
    height: 2px;
    background: $blue;
    @include mq(sp) {
      width: 100px;
    }
  }
}
.sub-title01 {
}
