@charset "UTF-8";
/* smartphone font-size */
/* media quary */
/* flex */
@import url("https://fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import url("https://fonts.googleapis.com/css?family=Muli");
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

html {
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Muli", sans-serif, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #231815;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}

a {
  color: #231815;
  text-decoration: none;
  transition: .2s;
}

a:hover {
  transition: .2s;
}

a:visited {
  color: #231815;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover img {
  opacity: 0.9;
}

.text {
  line-height: 1.5;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clear {
  clear: both;
}

/* show only  SP/PC
-------------------------------*/
@media screen and (min-width: 960px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  .sp-s {
    display: block !important;
  }
}

@media screen and (max-width: 599px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .pc-s {
    display: block !important;
  }
}

@media screen and (max-width: 599px) {
  .pc-s {
    display: none !important;
  }
}

/* @group float
-------------------------------- */
.right {
  float: right;
}

.left {
  float: left;
}

.img-left {
  float: left;
  margin: 0 20px 10px 0;
}

.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

/* @text-align
-------------------------------- */
.ta-right {
  text-align: right !important;
}

.ta-center {
  text-align: center !important;
}

.ta-left {
  text-align: left !important;
}

/* @vertical-align
-------------------------------- */
.va-top {
  vertical-align: top !important;
}

.va-bottom {
  vertical-align: bottom !important;
}

.va-middle {
  vertical-align: middle !important;
}

/* @group color
-------------------------------- */
.red {
  color: #EA5413 !important;
}

.blue {
  color: #0099ff !important;
}

.gray {
  color: #666 !important;
}

.pink {
  color: #C60070 !important;
}

.peach {
  color: #F86363 !important;
}

.green {
  color: #89B929 !important;
}

/* @group margin
-------------------------------- */
.m0 {
  margin: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml40 {
  margin-left: 40px !important;
}

/* @font size
-------------------------------- */
.fs10 {
  font-size: 10px !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs15 {
  font-size: 15px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs19 {
  font-size: 19px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs21 {
  font-size: 21px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs23 {
  font-size: 23px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs25 {
  font-size: 25px !important;
}

.fs26 {
  font-size: 26px !important;
}

.fs27 {
  font-size: 27px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs29 {
  font-size: 29px !important;
}

.fs30 {
  font-size: 30px !important;
}

.fs31 {
  font-size: 31px !important;
}

.fs32 {
  font-size: 32px !important;
}

.fs34 {
  font-size: 34px !important;
}

.fs36 {
  font-size: 36px !important;
}

.fs38 {
  font-size: 38px !important;
}

.fs48 {
  font-size: 48px !important;
}

.fs56 {
  font-size: 56px !important;
}

.fs64 {
  font-size: 64px !important;
}

/* @font-weight
-------------------------------- */
.fw-bold {
  font-weight: bold !important;
}

/* @border
-------------------------------- */
.bb-none {
  border-bottom: 0 !important;
}

.bl-none {
  border-left: 0 !important;
}

.br-none {
  border-right: 0 !important;
}

.bt-none {
  border-top: 0 !important;
}

/* @status font color
-------------------------------- */
.required {
  color: #c00;
}

.error-message {
  color: #c00;
}

/* @wp image
-------------------------------- */
.alignleft {
  float: left;
  margin: 0 20px 10px 0;
}

.alignright {
  float: right;
  margin: 0 0 10px 20px;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* @flex
-------------------------------- */
.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .flex {
    display: block;
    width: auto;
  }
}

.flex-sp {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

/* @font-family
-------------------------------- */
.mincho {
  font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.yu {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.noto {
  font-family: "Noto Sans Japanese";
}

.contents {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  margin-top: 60px;
  margin-bottom: 60px;
}

@media screen and (max-width: 599px) {
  .contents {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .contents {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 599px) {
  .contents {
    margin-top: 30px;
  }
}

.main {
  width: 850px;
}

@media screen and (max-width: 599px) {
  .main {
    width: auto;
    margin-bottom: 50px;
  }
}

.side {
  width: 300px;
}

@media screen and (max-width: 599px) {
  .side {
    width: auto;
  }
}

.base-wrap {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .base-wrap {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.base-wrap--space {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .base-wrap--space {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.screen-reader-text {
  display: none;
}

/*--------------------------------------------------------
  ヘッダー
----------------------------------------------------------*/
.header {
  margin-bottom: 40px;
}

@media screen and (max-width: 599px) {
  .header {
    margin-bottom: 10px;
  }
}

.header::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: -webkit-gradient(linear, left top, right bottom, from(#4D87B8), to(#53B6BE));
  background: -moz-linear-gradient(left, #4D87B8, #53B6BE);
  background: linear-gradient(left, #4D87B8, #53B6BE);
}

.header__inner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media screen and (max-width: 599px) {
  .header__inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.header__logo {
  padding: 36px 0 30px 20px;
}

@media screen and (max-width: 599px) {
  .header__logo {
    padding: 15px 30px;
  }
}

.header__logo a {
  display: block;
  width: 250px;
  height: 76px;
}

@media screen and (max-width: 599px) {
  .header__logo a {
    width: 200px;
    height: 60px;
  }
}

@media screen and (max-width: 599px) {
  .header__logo--en {
    padding: 20px 10px;
  }
}

.header__logo--en a {
  width: 600px;
  height: 35.5px;
}

@media screen and (max-width: 599px) {
  .header__logo--en a {
    width: 100%;
  }
}

.header__ja {
  position: absolute;
  right: 10px;
  bottom: 26px;
}

@media screen and (max-width: 599px) {
  .header__ja {
    right: 10px;
    bottom: 10px;
  }
}

.header__ja a {
  display: inline-block;
  padding: 15px 20px;
  background: #EAF0F0;
  border-radius: 3px;
}

@media screen and (max-width: 599px) {
  .header__ja a {
    padding: 10px 15px;
  }
}

.header__ja a::before {
  content: "> ";
}

.header__link {
  position: absolute;
  top: 28px;
  right: 32px;
  width: 230px;
}

@media screen and (max-width: 599px) {
  .header__link {
    position: static;
    width: auto;
    padding: 0 20px 20px;
  }
}

.header__search input[type=text] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 180px;
  padding: 5px 10px;
  border: 1px solid #999;
}

@media screen and (max-width: 599px) {
  .header__search input[type=text] {
    width: 80%;
    font-size: 18px;
  }
}

.header__search input[type=submit] {
  width: 20%;
  border: 0;
  background: #666;
  color: #fff;
  font-weight: bold;
  line-height: 22px;
}

@media screen and (max-width: 599px) {
  .header__search input[type=submit] {
    line-height: 30px;
  }
}

.nav-global {
  position: absolute;
  top: 0;
  left: 305px;
  width: 605px;
  z-index: 1000;
}

@media screen and (max-width: 599px) {
  .nav-global {
    position: static;
    width: auto;
  }
}

.nav-global__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
  height: 142px;
  border-left: 1px solid #999;
}

@media screen and (max-width: 599px) {
  .nav-global__list {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 599px) {
  .nav-global__list {
    height: auto;
    margin-bottom: 20px;
    border-left: none;
    border-top: 1px solid #999;
  }
}

.nav-global__list::before {
  position: absolute;
  left: -13px;
  bottom: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 73px 12px;
  border-color: transparent transparent #eaf0f0 transparent;
}

@media screen and (max-width: 599px) {
  .nav-global__list::before {
    display: none;
  }
}

.nav-global__list__item {
  position: relative;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item {
    border-bottom: 1px solid #fff;
  }
}

.nav-global__list__item > a {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 121px;
  height: 142px;
  padding-top: 90px;
  border-right: 1px solid #999;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item > a {
    width: auto;
    height: auto;
    padding: 10px;
    border-right: none;
    border-bottom: none;
    background: #006EAD;
    color: #fff;
    text-align: left;
  }
}

.nav-global__list__item > a::before {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 73px 12px;
  border-color: transparent transparent #eaf0f0 transparent;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item > a::before {
    display: none;
  }
}

.nav-global__list__item__category {
  padding: 10px;
  background: #006EAD;
  color: #fff;
}

.nav-global__list__item__category--service {
  padding-top: 40px;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item__category--service {
    padding-top: 10px;
  }
}

.nav-global__list__item__inner {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item__inner {
    position: static;
    display: block;
  }
}

.nav-global__list__item__child {
  background: #006EAD;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item__child {
    padding: 0 0 10px;
  }
}

.nav-global__list__item__child a {
  display: block;
  padding: 10px 20px 10px 15px;
  border-bottom: 1px solid #fff;
  color: #fff;
  white-space: nowrap;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item__child a {
    padding: 5px 20px 5px 15px;
    border-bottom: none;
  }
}

.nav-global__list__item__child a:hover {
  background: #008AB7;
}

.nav-global__list__item__child a::before {
  content: "> ";
}

.nav-global__list__item__child__item {
  position: relative;
}

.nav-global__list__item__child__item__menu {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
}

.nav-global__list__item__child__item__menu a {
  background: #008AB7;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item__child__item__menu a {
    padding-left: 2em;
  }
}

.nav-global__list__item__child__item__menu a:hover {
  background: #00A6B5;
}

.nav-global__list__item__child__item__menu__item:last-child a {
  border-bottom: 0;
}

.nav-global__list__item:nth-child(1) > a {
  background: url(/img/common/icn_top.svg) center 45% no-repeat;
  background-size: 22px 22px;
}

.nav-global__list__item:nth-child(1) > a:hover {
  background-color: #C0E0F2;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item:nth-child(1) > a {
    background: #006EAD;
  }
}

.nav-global__list__item:nth-child(2) > a {
  background: url(/img/common/icn_news.svg) center 45% no-repeat;
  background-size: 26px 26px;
}

.nav-global__list__item:nth-child(2) > a:hover {
  background-color: #C0E0F2;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item:nth-child(2) > a {
    background: #006EAD;
  }
}

.nav-global__list__item:nth-child(3) > a {
  background: url(/img/common/icn_prod.svg) center 45% no-repeat;
  background-size: 24px 36px;
}

.nav-global__list__item:nth-child(3) > a:hover {
  background-color: #C0E0F2;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item:nth-child(3) > a {
    background: #006EAD;
  }
}

.nav-global__list__item:nth-child(4) > a {
  background: url(/img/common/icn_tech.svg) center 45% no-repeat;
  background-size: 35px 25px;
}

.nav-global__list__item:nth-child(4) > a:hover {
  background-color: #C0E0F2;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item:nth-child(4) > a {
    background: #006EAD;
  }
}

.nav-global__list__item:nth-child(5) > a {
  background: url(/img/common/icn_about.svg) center 45% no-repeat;
  background-size: 17px 27px;
}

.nav-global__list__item:nth-child(5) > a:hover {
  background-color: #C0E0F2;
}

@media screen and (max-width: 599px) {
  .nav-global__list__item:nth-child(5) > a {
    background: #006EAD;
  }
}

.nav-sub {
  position: relative;
  margin-bottom: 14px;
}

.nav-sub__list__item {
  margin-bottom: 3px;
}

.nav-sub__list__item a {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 96px;
  padding: 3px 10px 3px 20px;
  background: #EAF0F0;
  border-radius: 5px;
  font-size: 11px;
}

@media screen and (max-width: 599px) {
  .nav-sub__list__item a {
    width: 48%;
  }
}

.nav-sub__list__item a::before {
  position: absolute;
  top: 5px;
  left: 6px;
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-top: 2px solid #231815;
  border-right: 2px solid #231815;
  transform: rotate(45deg);
}

.nav-sub__list__item a:hover {
  background: #eeffff;
  transform: translateY(1px);
}

.nav-sub__contact {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 599px) {
  .nav-sub__contact {
    width: 48%;
  }
}

.nav-sub__contact a {
  display: block;
  width: 128px;
  line-height: 57px;
  background: #006EAD;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .nav-sub__contact a {
    width: 100%;
  }
}

.nav-sub__contact a:hover {
  opacity: 0.8;
}

/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
.footer__inner {
  padding: 40px 0;
  background: #006EAD;
}

@media screen and (max-width: 599px) {
  .footer__inner {
    padding: 10px;
  }
}

.footer__navigation {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .footer__navigation {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .footer__navigation {
    display: block;
    width: auto;
  }
}

.footer__navigation a {
  color: #fff;
}

.footer--en::before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: -webkit-gradient(linear, left top, right bottom, from(#4D87B8), to(#53B6BE));
  background: -moz-linear-gradient(left, #4D87B8, #53B6BE);
  background: linear-gradient(left, #4D87B8, #53B6BE);
}

.footer--en .copyright {
  padding-top: 25px;
}

.nav-footer a:hover {
  text-decoration: underline;
}

.nav-footer__title {
  font-weight: bold;
  line-height: 2.5;
}

.nav-footer__list {
  line-height: 1.8;
}

.nav-footer__list__item {
  color: #fff;
}

.nav-footer__list__item::before {
  content: "・";
}

.nav-footer__list__item__menu {
  margin-left: 1em;
}

.nav-footer__list__item__menu__item::before {
  content: "> ";
}

.nav-footer__left {
  float: left;
  width: 50%;
}

@media screen and (max-width: 599px) {
  .nav-footer__left {
    float: none;
    width: 100%;
  }
}

.nav-footer__right {
  float: right;
  width: 50%;
}

@media screen and (max-width: 599px) {
  .nav-footer__right {
    float: none;
    width: 100%;
  }
}

.nav-footer__right .nav-footer__list__item:first-child::before {
  content: "";
}

.nav-footer--01 {
  width: 18%;
}

@media screen and (max-width: 599px) {
  .nav-footer--01 {
    width: 100%;
  }
}

.nav-footer--02 {
  width: 26%;
}

@media screen and (max-width: 599px) {
  .nav-footer--02 {
    width: 100%;
  }
}

.nav-footer--03 {
  width: 36%;
}

@media screen and (max-width: 599px) {
  .nav-footer--03 {
    width: 100%;
  }
}

.nav-footer--04 {
  width: 14%;
}

@media screen and (max-width: 599px) {
  .nav-footer--04 {
    width: 100%;
  }
}

.nav-flink {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 45px 0 30px;
}

@media screen and (max-width: 599px) {
  .nav-flink {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .nav-flink {
    padding: 10px 0;
  }
}

.nav-flink a:hover {
  text-decoration: underline;
}

.nav-flink__list {
  text-align: center;
}

@media screen and (max-width: 599px) {
  .nav-flink__list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
  }
}

.nav-flink__list__item {
  display: inline-block;
  padding: 0 20px;
  border-right: 1px solid #231815;
}

@media screen and (max-width: 599px) {
  .nav-flink__list__item {
    display: block;
    width: 48%;
    margin: 1%;
    padding: 0;
    border: none;
    line-height: 1.5;
  }
}

.nav-flink__list__item:last-child {
  border-right: 0;
}

@media screen and (max-width: 599px) {
  .nav-flink__list__item a {
    display: block;
    padding: 5px;
    border: 1px solid #231815;
  }
}

.copyright {
  padding: 0 0 35px;
  color: #999;
  font-size: 14px;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .copyright {
    font-size: 12px;
    font-size: 3.2vw;
    line-height: 1.3;
  }
}

/*--------------------------------------------------------
	サイドナビ
----------------------------------------------------------*/
.side__title {
  height: 104px;
  padding-left: 20px;
  background: url(/img/common/bg_side.jpg) 0 0 no-repeat;
  background-size: cover;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 104px;
}

.side__list {
  padding: 0 26px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.side__list__item {
  border-bottom: 1px dotted #ccc;
}

.side__list__item a {
  position: relative;
  display: block;
  padding: 20px 0 20px 20px;
  color: #666;
  font-size: 16px;
  font-weight: bold;
}

.side__list__item a::before {
  position: absolute;
  top: 23px;
  left: 2px;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ccc;
}

.side__list__item a:hover {
  text-decoration: underline;
}

.side__list__item:last-child a {
  border-bottom: none;
}

.side__list__item__menu {
  margin-top: -5px;
  margin-bottom: 10px;
}

.side__list__item__menu__item a {
  padding: 10px 0 10px 30px;
  border-bottom: none;
}

.side__list__item__menu__item a::before {
  left: 12px;
  top: 14px;
  width: 6px;
  height: 6px;
  background: none;
  border-radius: 0;
  border-top: 2px solid #ccc;
  border-right: 2px solid #ccc;
  transform: rotate(45deg);
}

/*--------------------------------------------------------
  スマホナビゲーション
----------------------------------------------------------*/
/* スマホグローバルナビ開閉ボタン */
@media screen and (max-width: 599px) {
  .nav-sp {
    display: none;
  }
}

.header__menu {
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 50px;
}

@media screen and (max-width: 599px) {
  .header__menu {
    display: block;
  }
}

#panel-btn {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  background: #0078B4;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#panel-btn .close {
  background: transparent;
}

#panel-btn .close::before, #panel-btn .close::after {
  margin-top: 0;
}

#panel-btn .close::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#panel-btn .close::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

#panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 4px;
  margin: -2px 0 0 -15px;
  background: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}

#panel-btn-icon::before, #panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 4px;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}

#panel-btn-icon::before {
  margin-top: -12px;
}

#panel-btn-icon::after {
  margin-top: 8px;
}

#BtnClose {
  display: none;
  padding: 20px 0 20px;
  background: #efefef;
  color: #0078B4;
  font-weight: bold;
  letter-spacing: .1em;
  text-align: center;
  font-size: 20px;
  font-size: 5.33333vw;
}

@media screen and (max-width: 599px) {
  #BtnClose {
    display: block;
  }
}

/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/
/* 中を<span>で囲む */
.button01 a {
  position: relative;
  display: inline-block;
  padding: 10px 76px 10px 16px;
  background: #000;
  color: #0078B4;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: .2em;
}

@media screen and (max-width: 599px) {
  .button01 a {
    display: block;
  }
}

.button01 a::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  content: "";
  width: 0;
  background: #0078B4;
  transition: .3s;
  z-index: 90;
}

.button01 a::after {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  content: '';
  width: 6px;
  height: 6px;
  margin-top: -3px;
  border-top: 1px solid #0078B4;
  border-right: 1px solid #0078B4;
  transform: rotate(45deg);
  transition: .3s;
  z-index: 91;
}

.button01 a:hover::before {
  width: 100%;
}

.button01 a:hover::after {
  border-color: #000;
}

.button01 a span {
  position: relative;
  z-index: 100;
  transition: .3s;
}

.button01 a:hover span {
  color: #000;
}

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
.title01 {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 2px solid #E1E1E1;
  color: #0078B4;
  font-size: 26px;
  font-weight: bold;
}

@media screen and (max-width: 599px) {
  .title01 {
    margin-bottom: 20px;
    font-size: 20px;
    font-size: 5.33333vw;
  }
}

.title01 span {
  margin-left: 30px;
  color: #231815;
  font-size: 14px;
  font-weight: normal;
}

@media screen and (max-width: 599px) {
  .title01 span {
    margin-left: 15px;
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.title01::after {
  position: absolute;
  bottom: -2px;
  left: 0;
  content: "";
  display: block;
  width: 320px;
  height: 2px;
  background: #0078B4;
}

@media screen and (max-width: 599px) {
  .title01::after {
    width: 100px;
  }
}

/*--------------------------------------------------------
  ぱんくず
----------------------------------------------------------*/
.breadcrumbs {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  color: #231815;
  font-size: 17px;
}

@media screen and (max-width: 599px) {
  .breadcrumbs {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.breadcrumbs a {
  display: inline-block;
  margin: 0 5px;
  color: #231815;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .separator01 {
  color: #000;
}

.breadcrumbs strong {
  display: inline-block;
  margin: 0 5px;
  font-weight: normal;
}

@media screen and (max-width: 599px) {
  .breadcrumbs {
    display: none;
  }
}

/*--------------------------------------------------------
  ページネーション
----------------------------------------------------------*/
.pagination {
  margin: 60px 0;
  text-align: center;
  font-size: 14px;
}

.pagination span.disabled,
.pagination span.prev a,
.pagination span.next a {
  margin: 0 20px;
  color: #0078B4;
}

.pagination span.current,
.pagination span.number a {
  margin: 0 4px;
  padding: 7px 12px;
  background: #dedede;
  color: #fff;
  text-decoration: none !important;
}

.pagination span.current,
.pagination span.number a:hover {
  background: #0078B4;
}

.pagination a:active {
  background: #0078B4;
}

/*--------------------------------------------------------
  コンテンツナビ
----------------------------------------------------------*/
.contents-navi {
  margin: 50px 0;
  text-align: center;
}

.contents-navi a {
  display: inline-block;
  padding: 11px 14px;
  color: #000;
}

.contents-navi a.prev-link, .contents-navi a.next-link {
  background: #ddd;
}

.contents-navi a:hover {
  color: #333;
}

.contents-navi a:hover.prev-link, .contents-navi a:hover.next-link {
  background: #eee;
}

/*--------------------------------------------------------
  ページ上部へ戻るボタン
----------------------------------------------------------*/
.page-top {
  position: fixed;
  bottom: 50px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: 550px;
  background: #C0E0F2;
  opacity: 0.8;
  cursor: pointer;
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 25px;
}

@media screen and (max-width: 599px) {
  .page-top {
    left: auto;
    right: 15px;
    bottom: 50px;
    margin-left: 0;
  }
}

/*--------------------------------------------------------
  テーブル
----------------------------------------------------------*/
.table01 {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid #000;
}

@media screen and (max-width: 599px) {
  .table01 {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.table01 tr {
  border-bottom: 1px solid #000;
}

.table01 th {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  color: #0078B4;
  font-size: 22px;
  text-align: left;
  letter-spacing: .1em;
}

@media screen and (max-width: 599px) {
  .table01 th {
    font-size: 20px;
    font-size: 5.33333vw;
    display: block;
    width: 100%;
    padding: 15px 10px 0 10px;
  }
}

.table01 td {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  font-size: 21px;
  line-height: 1.5;
}

@media screen and (max-width: 599px) {
  .table01 td {
    font-size: 18px;
    font-size: 4.8vw;
    display: block;
    width: 100%;
    padding: 10px 10px 15px;
  }
}

.table-scroll {
  overflow: auto;
  white-space: nowrap;
}

.table-scroll::-webkit-scrollbar {
  height: 5px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

/*--------------------------------------------------------
	icon
----------------------------------------------------------*/
/*--------------------------------------------------------
	リスト
----------------------------------------------------------*/
/*--------------------------------------------------------
  メインイメージ
----------------------------------------------------------*/
/* mainimage */
.mainimage {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .mainimage {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.mainimage__wrap {
  height: 450px;
  overflow: hidden;
}

@media screen and (max-width: 599px) {
  .mainimage__wrap {
    height: auto;
  }
}

@media screen and (max-width: 599px) {
  .bx-prev, .bx-next {
    display: none;
  }
  .bx-wrapper {
    margin-bottom: 20px;
  }
}

/*--------------------------------------------------------
	トップページ
----------------------------------------------------------*/
.top-prod__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 60px;
}

@media screen and (max-width: 599px) {
  .top-prod__list {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.top-prod__list__item {
  margin-bottom: 63px;
}

@media screen and (max-width: 599px) {
  .top-prod__list__item {
    width: 48%;
    margin-bottom: 14px;
  }
}

.top-prod__list__item:not(:nth-child(3n)) {
  margin-right: 63px;
}

@media screen and (max-width: 599px) {
  .top-prod__list__item:not(:nth-child(3n)) {
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .top-prod__list__item:not(:nth-child(2n)) {
    margin-right: 4%;
  }
}

.top-news__list {
  margin-bottom: 60px;
}

.top-news__list__item {
  border-bottom: 1px dotted #c4c4c4;
  line-height: 1.5;
}

.top-news__list__item a {
  display: block;
  padding: 20px 0;
}

.top-news__list__item a:hover .title {
  text-decoration: underline;
}

.top-news__list__item .date {
  float: left;
  display: block;
  width: 5em;
  margin-top: 1px;
  color: #c4c4c4;
  font-size: 11px;
}

.top-news__list__item .title {
  display: block;
  margin-left: 5em;
}

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/
.banner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}

@media screen and (max-width: 599px) {
  .banner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .banner {
    margin: 60px 0;
  }
}

.banner__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 599px) {
  .banner__list {
    display: block;
    width: auto;
  }
}

.banner__list__item {
  margin: 20px 10px;
}

@media screen and (max-width: 599px) {
  .banner__list__item {
    margin: 10px;
    text-align: center;
  }
}

.news__date {
  margin-bottom: 26px;
  color: #C4C4C4;
  font-size: 11px;
}

.news__body {
  margin-bottom: 50px;
  line-height: 1.75;
}

.news__body p {
  margin-bottom: 30px;
}
