@charset "utf-8";

/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
.footer {
  &__inner {
    padding: 40px 0;
    background: $blue2;
    @include mq(sp) {
      padding: 10px;
    }
  }
  &__navigation {
    @include base-wrap;
    @include flex();
    a {
      color: #fff;
    }
  }
}
.footer--en {
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: -webkit-gradient(linear, left top, right bottom, from(#4D87B8), to(#53B6BE));
    background: -moz-linear-gradient(left, #4D87B8, #53B6BE);
    background: linear-gradient(left, #4D87B8, #53B6BE);
  }
  .copyright {
    padding-top: 25px;
  }
}
.nav-footer {
  a:hover {
    text-decoration: underline;
  }
  &__title {
    font-weight: bold;
    line-height: 2.5;
  }
  &__list {
    line-height: 1.8;
    &__item {
      color: #fff;
      &::before {
        content: "・";
      }
      &__menu {
        margin-left: 1em;
        &__item {
          &::before {
            content: "> ";
          }
        }
      }
    }
  }
  &__left {
    float: left;
    width: 50%;
    @include mq(sp) {
      float: none;
      width: 100%;
    }
  }
  &__right {
    float: right;
    width: 50%;
    @include mq(sp) {
      float: none;
      width: 100%;
    }
    .nav-footer__list__item:first-child::before {
      content: "";
    }
  }
  &--01 {
    width: 18%;
    @include mq(sp) {
      width: 100%;
    }
  }
  &--02 {
    width: 26%;
    @include mq(sp) {
      width: 100%;
    }
  }
  &--03 {
    width: 36%;
    @include mq(sp) {
      width: 100%;
    }
  }
  &--04 {
    width: 14%;
    @include mq(sp) {
      width: 100%;
    }
  }
}
.nav-flink {
  @include base-wrap;
  padding: 45px 0 30px;
  @include mq(sp) {
    padding: 10px 0;
  }
  a:hover {
    text-decoration: underline;
  }
  &__list {
    text-align: center;
    @include mq(sp) {
      display: flex;
      flex-wrap: wrap;
      margin: 10px;
    }
    &__item {
      display: inline-block;
      padding: 0 20px;
      border-right: 1px solid $black;
      @include mq(sp) {
        display: block;
        width: 48%;
        margin: 1%;
        padding: 0;
        border: none;
        line-height: 1.5;
      }
      &:last-child {
        border-right: 0;
      }
      a {
        @include mq(sp) {
          display: block;
          padding: 5px;
          border: 1px solid $black;
        }
      }
    }
  }
}
.copyright {
  padding: 0 0 35px;
  color: #999;
  font-size: 14px;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(12);
    line-height: 1.3;
  }
}

