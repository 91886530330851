@charset "utf-8";

$black: #231815;
$blue: #0078B4;
$blue2: #006EAD;
$blue3: #008AB7;
$blue4: #00A6B5;
$blue5: #4D87B8;
$light-blue: #C0E0F2;
$bg-orange: #F7931E;
$deep-orange: #F15A24;
$light-orange: #FAE9D6;

$base-lh: 1.5;
$base-fs: 14px;

$mincho: "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
$font-yu: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$font-noto: "Noto Sans Japanese";
$font-avenir: "Avenir Next", Verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, Osaka, sans-serif;
$font-times: 'Times New Roman','Luchida Grande';
$font-muli: 'Muli', sans-serif;