@charset "utf-8";

/*--------------------------------------------------------
  ヘッダー
----------------------------------------------------------*/

.header {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 10px;
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: -webkit-gradient(linear, left top, right bottom, from(#4D87B8), to(#53B6BE));
    background: -moz-linear-gradient(left, #4D87B8, #53B6BE);
    background: linear-gradient(left, #4D87B8, #53B6BE);
  }
  &__inner {
    @include base-wrap;
    position: relative;
  }
  &__logo {
    padding: 36px 0 30px 20px;
    @include mq(sp) {
      padding: 15px 30px;
    }
    a {
      display: block;
      width: 250px;
      height: 76px;
      @include mq(sp) {
        width: 200px;
        height: 60px;
      }
    }
    &--en {
      @include mq(sp) {
        padding: 20px 10px;
      }
      a {
        width: 600px;
        height: 35.5px;
        @include mq(sp) {
          width: 100%;
        }
      }
    }
  }
  &__ja {
    position: absolute;
    right: 10px;
    bottom: 26px;
    @include mq(sp) {
      right: 10px;
      bottom: 10px;
    }
    a {
      display: inline-block;
      padding: 15px 20px;
      background: #EAF0F0;
      border-radius: 3px;
      @include mq(sp) {
        padding: 10px 15px;
      }
      &::before {
        content: "> "
      }
    }
  }
  &__link {
    position: absolute;
    top: 28px;
    right: 32px;
    width: 230px;
    @include mq(sp) {
      position: static;
      width: auto;
      padding: 0 20px 20px;
    }
  }
  &__search {
    input[type=text] {
      @include box-sizing(border-box);
      width: 180px;
      padding: 5px 10px;
      border: 1px solid #999;
      @include mq(sp) {
        width: 80%;
        font-size: 18px;
      }
    }
    input[type=submit] {
      width: 20%;
      border: 0;
      background: #666;
      color: #fff;
      font-weight: bold;
      line-height: 22px;
      @include mq(sp) {
        line-height: 30px;
      }
    }
  }
}
.nav-global {
  position: absolute;
  top: 0;
  left: 305px;
  width: 605px;
  z-index: 1000;
  @include mq(sp) {
    position: static;
    width: auto;
  }
  &__list {
    @include flex();
    position: relative;
    height: 142px;
    border-left: 1px solid #999;
    @include mq(sp) {
      height: auto;
      margin-bottom: 20px;
      border-left: none;
      border-top: 1px solid #999;
    }
    &::before {
      position: absolute;
      left: -13px;
      bottom: 0;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 73px 12px;
      border-color: transparent transparent #eaf0f0 transparent;
      @include mq(sp) {
        display: none;
      }
    }
    &__item {
      position: relative;
      @include mq(sp) {
        border-bottom: 1px solid #fff;
      }
      & > a {
        @include box-sizing(border-box);
        position: relative;
        display: block;
        width: 121px;
        height: 142px;
        padding-top: 90px;
        border-right: 1px solid #999;
        text-align: center;
        @include mq(sp) {
          width: auto;
          height: auto;
          padding: 10px;
          border-right: none;
          border-bottom: none;
          background: $blue2;
          color: #fff;
          text-align: left;
        }
        &::before {
          position: absolute;
          right: 0;
          bottom: 0;
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 73px 12px;
          border-color: transparent transparent #eaf0f0 transparent;
          @include mq(sp) {
            display: none;
          }
        }
      }
      &__category {
        padding: 10px;
        background: $blue2;
        color: #fff;
        @include mq(sp) {
        }
        &--service {
          padding-top: 40px;
          @include mq(sp) {
            padding-top: 10px;
          }
        }
      }
      &__inner {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        @include mq(sp) {
          position: static;
          display: block;
        }
      }
      &__child {
        background: $blue2;
        @include mq(sp) {
          padding: 0 0 10px;
        }
        a {
          display: block;
          padding: 10px 20px 10px 15px;
          border-bottom: 1px solid #fff;
          color: #fff;
          white-space: nowrap;
          @include mq(sp) {
            padding: 5px 20px 5px 15px;
            border-bottom: none;
          }
          &:hover {
            background: $blue3;
          }
          &::before {
            content: "> ";
          }
        }
        &__item {
          position: relative;
          &__menu {
            position: absolute;
            top: 0;
            left: 100%;
            display: none;
            a {
              background: $blue3;
              @include mq(sp) {
                padding-left: 2em;
              }
              &:hover {
                background: $blue4;
              }
            }
            &__item {
              &:last-child {
                a {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.nav-global__list__item:nth-child(1) > a {
  background: url(/img/common/icn_top.svg) center 45% no-repeat;
  background-size: 22px 22px;
  &:hover {
    background-color: $light-blue;
  }
  @include mq(sp) {
    background: $blue2;
  }
}
.nav-global__list__item:nth-child(2) > a {
  background: url(/img/common/icn_news.svg) center 45% no-repeat;
  background-size: 26px 26px;
  &:hover {
    background-color: $light-blue;
  }
  @include mq(sp) {
    background: $blue2;
  }
}
.nav-global__list__item:nth-child(3) > a {
  background: url(/img/common/icn_prod.svg) center 45% no-repeat;
  background-size: 24px 36px;
  &:hover {
    background-color: $light-blue;
  }
  @include mq(sp) {
    background: $blue2;
  }
}
.nav-global__list__item:nth-child(4) > a {
  background: url(/img/common/icn_tech.svg) center 45% no-repeat;
  background-size: 35px 25px;
  &:hover {
    background-color: $light-blue;
  }
  @include mq(sp) {
    background: $blue2;
  }
}
.nav-global__list__item:nth-child(5) > a {
  background: url(/img/common/icn_about.svg) center 45% no-repeat;
  background-size: 17px 27px;
  &:hover {
    background-color: $light-blue;
  }
  @include mq(sp) {
    background: $blue2;
  }
}

.nav-sub {
  position: relative;
  margin-bottom: 14px;
  &__list {
    &__item {
      margin-bottom: 3px;
      a {
        @include box-sizing(border-box);
        position: relative;
        display: block;
        width: 96px;
        padding: 3px 10px 3px 20px;
        background: #EAF0F0;
        border-radius: 5px;
        font-size: 11px;
        @include mq(sp) {
          width: 48%;
        }
        &::before {
          position: absolute;
          top: 5px;
          left: 6px;
          content: "";
          display: block;
          width: 4px;
          height: 4px;
          border-top: 2px solid $black;
          border-right: 2px solid $black;
          transform: rotate(45deg);
        }
        &:hover {
          background: #eeffff;
          transform: translateY(1px);
        }
      }
    }
  }
  &__contact {
    position: absolute;
    top: 0;
    right: 0;
    @include mq(sp) {
      width: 48%;
    }
    a {
      display: block;
      width: 128px;
      line-height: 57px;
      background: $blue2;
      border-radius: 5px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      @include mq(sp) {
        width: 100%;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

