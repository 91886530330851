@charset "utf-8";

/*--------------------------------------------------------
	サイドナビ
----------------------------------------------------------*/
.side {
  &__title {
    height: 104px;
    padding-left: 20px;
    background: url(/img/common/bg_side.jpg) 0 0 no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    line-height: 104px;
  }
  &__list {
    padding: 0 26px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    &__item {
      border-bottom: 1px dotted #ccc;
      a {
        position: relative;
        display: block;
        padding: 20px 0 20px 20px;
        color: #666;
        font-size: 16px;
        font-weight: bold;
        &::before {
          position: absolute;
          top: 23px;
          left: 2px;
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #ccc;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      &:last-child
      a {
        border-bottom: none;
      }
      &__menu {
        margin-top: -5px;
        margin-bottom: 10px;
        &__item {
          a {
            padding: 10px 0 10px 30px;
            border-bottom: none;
            &::before {
              left: 12px;
              top: 14px;
              width: 6px;
              height: 6px;
              background: none;
              border-radius: 0;
              border-top: 2px solid #ccc;
              border-right: 2px solid #ccc;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}