
/*--------------------------------------------------------
  ぱんくず
----------------------------------------------------------*/

.breadcrumbs {
  @include base-wrap--space;
  color: $black;
  font-size: 17px;
  a {
    display: inline-block;
    margin: 0 5px;
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }
  .separator01 {
    color: #000;
  }
  strong {
    display: inline-block;
    margin: 0 5px;
    font-weight: normal;
  }
}
.breadcrumbs {
  @include mq(sp) {
    display: none;
  }
}