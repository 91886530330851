@charset "utf-8";

/*--------------------------------------------------------
	トップページ
----------------------------------------------------------*/

.top-prod {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 60px;
    @include mq(sp) {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    &__item {
      margin-bottom: 63px;
      @include mq(sp) {
        width: 48%;
        margin-bottom: 14px;
      }
    }
    &__item:not(:nth-child(3n)) {
      margin-right: 63px;
      @include mq(sp) {
        margin-right: 0;
      }
    }
    &__item:not(:nth-child(2n)) {
      @include mq(sp) {
        margin-right: 4%;
      }
    }
  }
}
.top-news {
  &__list {
    margin-bottom: 60px;
    &__item {
      border-bottom: 1px dotted #c4c4c4;
      line-height: 1.5;
      a {
        display: block;
        padding: 20px 0;
        &:hover .title {
          text-decoration: underline;
        }
      }
      .date {
        float: left;
        display: block;
        width: 5em;
        margin-top: 1px;
        color: #c4c4c4;
        font-size: 11px;
      }
      .title {
        display: block;
        margin-left: 5em;
      }
    }
  }
}