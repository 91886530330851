@charset "utf-8";

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/
.banner {
  @include base-wrap;
  margin-bottom: 60px;
  @include mq(sp) {
    margin: 60px 0;
  }
  &__list {
    @include flex(wrap);
    justify-content: center;
    &__item {
      margin: 20px 10px;
      @include mq(sp) {
        margin: 10px;
        text-align: center;
      }
    }
  }
}

.news {
  &__date {
    margin-bottom: 26px;
    color: #C4C4C4;
    font-size: 11px;
  }
  &__body {
    margin-bottom: 50px;
    line-height: 1.75;
    p {
      margin-bottom: 30px;
    }
  }
}
