@charset "utf-8";

/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/

/* 中を<span>で囲む */
.button01 {
  a {
    position: relative;
    display: inline-block;
    padding: 10px 76px 10px 16px;
    background: #000;
    color: $blue;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .2em;
    @include mq(sp) {
      display: block;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      content: "";
      width: 0;
      background: $blue;
      transition: .3s;
      z-index: 90;
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 20px;
      display: block;
      content: '';
      width: 6px;
      height: 6px;
      margin-top: -3px;
      border-top: 1px solid $blue;
      border-right: 1px solid $blue;
      transform: rotate(45deg);
      transition: .3s;
      z-index: 91;
    }
    &:hover::before {
      width: 100%;
    }
    &:hover::after {
      border-color: #000;
    }
    span {
      position: relative;
      z-index: 100;
      transition: .3s;
    }
    &:hover {
      span {
        color: #000;
      }
    }
  }
}
