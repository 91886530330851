@charset "utf-8";

/*--------------------------------------------------------
  テーブル
----------------------------------------------------------*/
.table01 {
  @include base-wrap--space;
  @include box-sizing(border-box);
  border-top: 1px solid #000;
  tr {
    border-bottom: 1px solid #000;
  }
  th {
    @include box-sizing(border-box);
    padding: 20px;
    color: $blue;
    font-size: 22px;
    text-align: left;
    letter-spacing: .1em;
    @include mq(sp) {
      @include fz_vw(20);
      display: block;
      width: 100%;
      padding: 15px 10px 0 10px;
    }
  }
  td {
    @include box-sizing(border-box);
    padding: 20px;
    font-size: 21px;
    line-height: 1.5;
    @include mq(sp) {
      @include fz_vw(18);
      display: block;
      width: 100%;
      padding: 10px 10px 15px;
    }
  }
}

.table-scroll {
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCBCBC;
  }
}
